import {
    COMMUNICATION_TYPE, PRODUCT_DISRUPTION_TYPES, PRODUCT_DISRUPTION_TYPE, PRODUCT_DISRUPTION_EDIT_TYPE, PRODUCT_DISRUPTION_REASON,
    RECALL_CLASSIFICATIONS, ALLOWEDEXTENSIONS, ALLOWEDIMAGEEXTENSIONS, PRODUCT_DISRUPTION_EDIT_WITH_MARKET_GENERAL_INFO_EVENT_TYPE,
    ANTICIPATED_DURATION
} from '../../../../misc/commons'
import supplierRecallService from '../../../../services/supplier/supplier-recall'
import _ from 'lodash'
import moment from 'moment-timezone'
import noti from '../../../../utils/noti_toast'
import recallService from '../../../../services/provider/recall'
import siteService from '../../../../services/provider/sites'
import VueTagsInput from '@johmun/vue-tags-input'
import store from '../../../../store'
import { EMAIL_STATUS } from '../../../../misc/commons'
export default {
    components: {
        VueTagsInput
    },
    props: ['infoData', 'recallMetaData', 'isDisabled', 'autonotifychecked', 'SupplierContactlst', 'communicationType', 'recallDisabled'],
    data() {
        return {
            disabledEmail: [],
            defaultEmailList: [],
            combineEmailList: null,
            disableEmailTagList: false,
            originalValueTag: [],
            value: 'name',
            selected: null,
            dateHighlight: true,
            dateHighlightNextTaskReminder: true,
            extensionAllowed: ALLOWEDEXTENSIONS,
            extensionImageAllowed: ALLOWEDIMAGEEXTENSIONS,
            recall: COMMUNICATION_TYPE.RECALL,
            productDisruptionTypes: PRODUCT_DISRUPTION_TYPES,
            productDisruption: COMMUNICATION_TYPE.PRODUCTDISRUPTION,
            productdisruptiontype: PRODUCT_DISRUPTION_TYPE,
            productdisruptionwithmarketandgeneralinformationeventtype: PRODUCT_DISRUPTION_EDIT_WITH_MARKET_GENERAL_INFO_EVENT_TYPE,
            productdisruptionedittype: PRODUCT_DISRUPTION_EDIT_TYPE,
            productdisruptionreason: PRODUCT_DISRUPTION_REASON,
            autocompleteItems: [],
            recallClassificationOptions: RECALL_CLASSIFICATIONS,
            anticipatedDurationOptions: ANTICIPATED_DURATION,
            isSalesRepNotification: false,
            info: null,
            filesUpload: [],
            additionalResources: [],
            eventType: '',
            thumbnailIndex: -1,
            isCustomizedContentDisabled: false,
            siteId: 0,
            recallsite: [],
            RecallSiteCount: 0,
            valueTag: [],
            enableValueTag: [],
            tags: [],
            tag: '',
            emailError: '',
            ccvalue: [],
            options: [],
            autocompleteItemsR: [],
            valueTagR: [],
            tagR: '',
            emailErrorR: '',
            valueR: [],
            resourceName: '',
            url: '',
            emailStatus: EMAIL_STATUS,
            dependentDisabledDates: {
                to: new Date()
            },
            disabledDates: {
                from: new Date()
            },
            disableNextReminderDate: {
                to: moment().startOf('day').toDate()
            },
            highlighted:
            {
                customPredictor: function (dates) {

                    if (moment(dates).format('YYYY/MM/DD') === moment(new Date()).format('YYYY/MM/DD')) {
                        //  alert(moment(dates).format('YYYY/MM/DD'))
                        return true
                    }
                },
            },
            labels: {},
            orgType: store.state.userType,
            service: store.state.userType == 'SUPPLIER' ? supplierRecallService : recallService,
        }
    },
    created() {
        this.labels = {
            name: this.communicationType == this.recall ? 'Recall Name *' : 'Supply Advisory Name *',
            type: this.communicationType == this.recall ? 'Recall Type *' : 'Type *',
            date: this.communicationType == this.recall ? 'Recall Date *' : 'Advisory Date *',
            communicationUID: this.communicationType == this.recall ? 'Recall ID *' : 'Supply Advisory ID *',
            status: this.communicationType == this.recall ? 'Recall Status' : 'Status',
            riskToHealth: this.communicationType == this.recall ? 'Risk to Health' : 'Alternatives & Conservation Measures',
            categories: this.communicationType == this.recall ? 'Recall Categories' : 'Category',
            image: this.communicationType == this.recall ? 'Recall Image' : 'Supply Advisory Image'
        }
        if (this.orgType == 'PROVIDER') {
            this.getEmailList()
            recallService.GetSupplierNameList().then((res) => {
                for (let i = 0; i < res.data.d.length; i++) {
                    this.autocompleteItems.push(res.data.d[i].text)
                }
            })
        }
    },
    mounted() {
        this.clearCurrentHiglightDateInEdit()
        this.info = _.cloneDeep(this.infoData)
        if (this.orgType == 'SUPPLIER') {
            this.disableEditableDate()
            if (this.info.id == '' || this.info.id == null || this.info.id == undefined) {
                this.info.isCustomizedContent = this.recallMetaData.isCustomizedContent
                this.info.allowSalesRepNotification = this.recallMetaData.allowSalesRepNotification
            }
            if (this.info.products.length > 0 || this.info.actions.length > 0) {
                this.isCustomizedContentDisabled = true
            }
        }
        if(this.info.resources != '' && this.info.resources != undefined && this.info.resources != null)
        {
            this.additionalResources = this.info.resources.map(x => {
                return {
                    id: x.id,
                    resourceName: x.resourceName,
                    url: x.url
                }
            })
        }
        if (this.orgType == 'PROVIDER') {
            this.setProviderCommunicationName()
            if (this.infoData.communicationUID != '') {
                this.RecallSiteCount = this.info.recallsite.length
                var selectedorg = []
                if (this.info.recallsite != null) {
                    for (let i = 0; i < this.info.recallsite.length; i++) {
                        selectedorg.push(this.info.recallsite[i].value)
                    }
                }
                this.recallsite = selectedorg
                for (let i = 0; i < this.recallMetaData.recall_usersites.filter(({ value }) => this.recallsite.includes(value)).length; i++) {
                    this.recallMetaData.recall_usersites.filter(({ value }) => this.recallsite.includes(value))[i].isSelected = true
                }
                this.bindSupplierContactList()
            } else {
                this.getUserSitesById()
            }
            if (this.infoData.responseEmail != '' & this.infoData.responseEmail != null) {
                this.valueR.push(this.infoData.responseEmail)
                if (!this.info.allowResponseEmail) {
                    this.valueTagR.push({ 'text': this.infoData.responseEmail, 'classes': ['ti-tag-disable-close'] })
                }
                else {
                    this.valueTagR.push(this.infoData.responseEmail)
                }
            }
        }
        this.filesUpload = this.info.documents.map(x => {
            return {
                id: x.id,
                file: {
                    name: x.name,
                    type: x.type,
                    size: x.size,
                    extension: x.extension
                },
                note: x.note,
                is_thumbnail: x.is_thumbnail,
                extension: x.extension.slice(1)
            }
        })
        this.thumbnailIndex = this.filesUpload.findIndex(x => !!x.is_thumbnail)
        this.info.distributorName = this.info.distributorName == 'undefined' ? null : this.info.distributorName
        this.info.allowResponseEmail = this.info.allowResponseEmail == undefined ? true : this.info.allowResponseEmail
        this.eventType = this.info.type
    },
    computed: {
        isRequired() {
            if (this.infoData && ['OPEN', 'CLOSED'].includes(this.infoData.status)) {
                return true
            } else {
                return false
            }
        },
        filteredEmailItems() {
            return this.autocompleteItemsR.filter((i) => {
                return i.text.toLowerCase().indexOf(this.tagR.toLowerCase()) !== -1
            })
        },
        filteredEmailItemsC() {
            return this.autocompleteItemsR.filter((i) => {
                return i.text.toLowerCase().indexOf(this.tag.toLowerCase()) !== -1
            })
        },
    },
    methods: {
        getEmailList() {
            this.service.GetEmailList(this.$store.state.orgId)
                .then((resp) => {
                    if (!resp.error) {
                        resp.data.d.forEach((val) => {
                            this.autocompleteItemsR.push({
                                text: val.email
                            })
                        })
                    }
                })
        },
        bindSupplierContactList() {
            //var supplierlst = this.SupplierContactlst.filter(s => s.supplierName == this.info.supplierName)
            this.valueTag = []
            this.enableValueTag = []
            this.options = []
            this.ccvalue = []
            this.originalValueTag = []
            var supplierCcEmails = this.info?.supplierRepCCEmails == undefined ? this.info?.SupplierRepCCEmail : this.info?.supplierRepCCEmails
            var additionalRepEmails = this.info?.additionalCcEmails
            if (this.$route.params.id != null) {
                this.info?.supplierRepCCEmails?.forEach((val) => {
                    this.defaultEmailList.push({ email: val })
                })
            }
            this.combineEmailList = supplierCcEmails?.concat(additionalRepEmails)
            this.combineEmailList?.forEach(x => {
                if (!this.options.includes(x)) {
                    this.options.push(x)
                }
                if (!this.ccvalue?.includes(x)) {
                    this.ccvalue.push(x)
                }
            })
            if(supplierCcEmails != undefined)
            {
                supplierCcEmails.forEach(x => {
                    if (this.valueTag.filter(s => s.text == x).length <= 0) {
                        this.valueTag.push({ 'text': x, 'classes': ['ti-tag-disable-close'] })
                        this.originalValueTag.push({ 'text': x, 'classes': ['ti-tag-disable-close'] })
                        this.disableEmailTagList = true
                    }
                })
            }
            if(additionalRepEmails != undefined)
            {
                additionalRepEmails?.forEach(x => {
                    if (this.valueTag.filter(s => s.text == x).length <= 0) {
                        this.enableValueTag.push({ 'text': x })
                        this.valueTag.push({ 'text': x })
                    }
                })
            }
        },
        async addAdditionalResources(resourceName, url) {
            this.$refs['resourcesobserver'].validate().then(isValid => {
                if (this.url == '') {
                    isValid = false
                    noti.error('Error', 'Please enter valid url.')
                }
                if (isValid) {
                    this.additionalResources.push({ resourceName: resourceName ? resourceName : url, url: url })
                    this.resourceName = '',
                    this.url = ''
                }
            })
        },
        removeField(index) {
            if (this.additionalResources && this.additionalResources.length > 0) {
                this.additionalResources.splice(index, 1)
            }
        },
        handleChangeSupplierName(suppliername) {
            var supplierlst = this.SupplierContactlst.filter(s => s.supplierName == suppliername)
            this.defaultEmailList = supplierlst
            if (this.autonotifychecked) {
                this.originalValueTag = []
                this.valueTag = []
                this.enableValueTag = []
                this.options = []
                this.ccvalue = []
                supplierlst.forEach(x => {
                    if (!this.options.includes(x.email)) {
                        this.options.push(x.email)
                    }
                    if (!this.ccvalue.includes(x.email)) {
                        this.ccvalue.push(x.email)
                        this.disabledEmail.push(x.email)
                    }
                    if (this.valueTag.filter(s => s.text == x.email).length <= 0) {
                        this.originalValueTag.push({ 'text': x.email, 'classes': ['ti-tag-disable-close'] })
                        this.valueTag.push({ 'text': x.email, 'classes': ['ti-tag-disable-close'] })
                        this.disableEmailTagList = true
                    }
                })
            }
            if (this.$route.params.id != null && supplierlst.length < 1) {
                this.disableEmailTagList = false
            }
            if (this.$route.params.id == null && supplierlst.length < 1) {
                this.disableEmailTagList = false
            }
        },
        addCheck(val) {
            let isDuplicateEmail = false
            let tagArray = []
            let emailArray = []
            this.valueTag = []
            let duplicateEmail = []
            this.originalValueTag.forEach(x => {
                if (emailArray.includes(x.text)) {
                    duplicateEmail.push(x.text)
                    isDuplicateEmail = true
                }
                emailArray.push(x.text)
                tagArray.push(x)
            })
            val.forEach(x => {
                if (x.tiClasses.includes('ti-duplicate')) {
                    duplicateEmail.push(x.text)
                    isDuplicateEmail = true
                }
                if (this.originalValueTag.find(y => y.text == x.text) == null && !x.tiClasses.includes('ti-duplicate')) {
                    tagArray.push(x)
                }
            })
            val = []
            this.valueTag = tagArray
            val = tagArray
            if (isDuplicateEmail) {
                duplicateEmail.forEach(x => {
                    val.push(x)
                })
                this.setvalidation()
                this.emailError = 'The email has been already used.'
            }
            else {
                if (val.length > 0) {
                    this.ccvalue = []
                    val.forEach(x => {

                        let Isval = this.checkEmailValidation(x.text)
                        if (Isval) {
                            this.ccvalue.push(x.text)
                        }
                    })
                    if (this.ccvalue.length > 0) {
                        var valueOriginal = this.ccvalue.length
                        var valcurrent = val.length - 1
                        const re = /^(([^<>()\\.,;:\s@"]+(\.[^<>()\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/
                        var b = re.test(String(val[valcurrent].text.trim()).toLowerCase())
                        if (b) {
                            var i = 0
                            for (i = 0; i < valueOriginal - 1; i++) {
                                if (this.ccvalue[i].trim() === val[valcurrent].text.trim()) {
                                    this.setvalidation()
                                    this.emailError = 'The email has been already used.'
                                }
                            }

                        }
                    }
                }
                else {
                    this.removevalidation()
                    this.ccvalue = []
                    this.domainError = ''
                }
            }
        },
        addRespCheck(val) {
            if (val.length > 1) {
                this._showToast('Only one response email allowed', { variant: 'warning' })
                val.pop()
            }
            else {
                if (val.length > 0) {
                    this.valueR = []
                    val.forEach(x => {
                        let IsVal = this.checkResponseEmailValidation(x.text)
                        if (!this.emailErrorR && IsVal) {
                            this.valueR.push(x.text)
                        }
                        else {
                            val.pop()
                        }
                    })
                }
                else {
                    this.removeResponsevalidation()
                    this.valueR = []
                    this.domainError = ''
                }
            }
        },
        checkEmailValidation(email) {
            let Isval = true
            if (!email) {
                this.setvalidation()
                this.emailError = 'Email is required'
                Isval = false
            } else { this.emailError = '' }
            if (email) {
                const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                var b = re.test(String(email.trim()).toLowerCase())
                this.removevalidation()
                if (!b) {
                    this._showToast('Please enter valid email address', { variant: 'danger' })
                    this.setvalidation()
                    Isval = false
                }
            }
            return Isval
        },
        checkResponseEmailValidation(email) {
            let Isval = true
            if (!email) {
                this.setResponsevalidation()
                this.emailErrorE = 'Email is required'
                Isval = false
            } else { this.emailErrorE = '' }
            if (email) {
                const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                var b = re.test(String(email.trim()).toLowerCase())
                this.removeResponsevalidation()
                if (!b) {
                    this._showToast('Please enter valid email address', { variant: 'warning' })
                    this.setResponsevalidation()
                    Isval = false
                }
            }
            return Isval
        },
        setResponsevalidation() {
            var ele = this.$refs.recipientsRespEmail.$el.getElementsByClassName('ti-input')
            ele[0].style = 'border-color:red !important'
        },
        removeResponsevalidation() {
            var ele = this.$refs.recipientsRespEmail.$el.getElementsByClassName('ti-input')
            ele[0].style = ' '
        },
        addResponseemailcolor() {
            var ele = this.$refs.recipientsRespEmail.$el.getElementsByClassName('ti-input')
            ele[0].style = 'border-color:#5ec3ff !important'
        },
        setvalidation() {
            var ele = this.$refs.recipientsEmail.$el.getElementsByClassName('ti-input')
            ele[0].style = 'border-color:red !important'
        },
        removevalidation() {
            var ele = this.$refs.recipientsEmail.$el.getElementsByClassName('ti-input')
            ele[0].style = ' '
        },
        addemailcolor() {
            var ele = this.$refs.recipientsEmail.$el.getElementsByClassName('ti-input')
            ele[0].style = 'border-color:#5ec3ff !important'
        },
        getUserSitesById() {
            siteService.getUserSitesById().then((resp) => {
                if (!resp.error) {
                    var selectedorg = []
                    if (resp.data.d != null && resp.data.d.length > 0) {
                        for (let i = 0; i < resp.data.d.length; i++) {
                            selectedorg.push(resp.data.d[i].value)
                        }
                    } else {
                        var Corporatesitemeta = this.recallMetaData.recall_usersites.filter(
                            (s) => s.text == 'Corporate'
                        )
                        for (let i = 0; i < Corporatesitemeta.length; i++) {
                            selectedorg.push(Corporatesitemeta[i].value)
                        }
                    }
                    this.recallsite = selectedorg
                }
            })
        },
        save(type) {
            return new Promise((resolve, reject) => {
                this.$refs['observer'].validate().then(isValid => {
                    if (isValid) {
                        let formData = new FormData()
                        formData.append('id', this.info.id)
                        formData.append('communicationType', this.communicationType)
                        formData.append('name', this.info.name)
                        formData.append('communicationUID', this.info.communicationUID)
                        formData.append('type', this.info.type ? this.info.type : '')
                        formData.append('status', this.info.status)
                        formData.append('communicationDate', this.info.communicationDate ? moment(this.info.communicationDate).format('YYYY/MM/DD') : '')
                        formData.append('nextExpectedUpdate', (this.info.nextExpectedUpdate && this.info.nextExpectedUpdate != 'Not Applicable') ? moment(this.info.nextExpectedUpdate).format('YYYY/MM/DD') : '')
                        if (this.info.anticipatedDurationId !== null && this.communicationType == this.productDisruption) {
                            formData.append('anticipatedDurationId', this.info.anticipatedDurationId)
                        }
                        formData.append('nextTaskReminder', this.info.nextTaskReminder ? moment(this.info.nextTaskReminder).format('YYYY/MM/DD') : '')
                        if (this.info?.allocationProcess !== null && this.communicationType == this.productDisruption) {
                            formData.append('allocationProcess', this.info.allocationProcess)
                        }
                        formData.append('zNumber', this.info.zNumber ? this.info.zNumber.trim() : '')
                        formData.append('classification', this.info.classification ? this.info.classification : '')
                        formData.append('isUrgent', this.info.isUrgent)
                        formData.append('isCustomizedContent', this.info.isCustomizedContent ? this.info.isCustomizedContent : false)
                        formData.append('allowSalesRepNotification', this.info.allowSalesRepNotification ? this.info.allowSalesRepNotification : false)
                        formData.append('markAs', this.info.markAs)
                        formData.append('description', this.info.description ? this.info.description.trim() : '')
                        formData.append('riskToHealth', this.info.riskToHealth ? this.info.riskToHealth.trim() : '')
                        formData.append('supplierName', this.info.supplierName)
                        formData.append('distributorName', this.info.distributorName)
                        formData.append('notifiedDate', this.info.notifiedDate ? moment(this.info.notifiedDate).format('YYYY/MM/DD') : '')
                        if (this.info.categories) {
                            this.info.categories.forEach(category => {
                                formData.append('categories[]', category)
                            })
                        }
                        this.filesUpload.forEach((x, i) => {
                            if (!x.id) {
                                if(x.file.url) {
                                    formData.append('documentInfo[' + i + '].title', x.file.title.trim())
                                    formData.append('documentInfo[' + i + '].url', x.file.url)
                                } else {
                                    formData.append('document', x.file)
                                }
                            }
                            formData.append('documentInfo[' + i + '].id', x.id ? x.id : 0)
                            formData.append('documentInfo[' + i + '].note', x.note ? x.note.trim() : '')
                            formData.append('documentInfo[' + i + '].isThumbnail', x.is_thumbnail)
                        })
                        this.additionalResources.forEach((x, i) => {
                            formData.append('resourceInfo[' + i + '].id', x.id ? x.id : 0)
                            formData.append('resourceInfo[' + i + '].resourceName', x.resourceName ? x.resourceName.trim() : x.url)
                            formData.append('resourceInfo[' + i + '].url', x.url ? x.url : 0)
                        })
                        if (this.orgType == 'PROVIDER') {
                            var cnt = 0
                            for (let k = 0; k < this.ccvalue.length; k++) {
                                if(this.ccvalue[k] != undefined)
                                {
                                    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                                    var b = re.test(String(this.ccvalue[k].trim()).toLowerCase())
                                    this.removevalidation()
                                    if (!b) {
                                        this.setvalidation()
                                        cnt = cnt + 1
                                    }
                                }
                            }
                            //Add duplicate email validation
                            if (this.ccvalue.length > 1) {
                                for (let i = 0; i < this.ccvalue.length; i++) {
                                    for (var k = i + 1; k < this.ccvalue.length; k++) {
                                        if(this.ccvalue[k] != undefined && this.ccvalue[i] != undefined)
                                        {
                                            if (this.ccvalue[i].trim() === this.ccvalue[k].trim()) {
                                                this.setvalidation()
                                                return this.emailError = 'The email has been already used.'
                                            }
                                        }
                                    }
                                }
                            }
                            if (this.ccvalue.length > 0) {
                                for (let k = 0; k < this.valueTag.length; k++) {
                                    if (this.ccvalue.find(x => x == this.valueTag[k].text)) {
                                    }
                                    else {
                                        this.setvalidation()
                                        return this.emailError = 'The email has been already used.'
                                    }
                                }
                            }
                            if (cnt !== 0) {
                                return this._showToast('Invalid email address.', { variant: 'danger' })
                            }
                            formData.append('clicktype', type)

                            if (this.valueR.length > 0) {
                                formData.append('responseEmail', this.valueR[0])
                            }
                            if (this.recallsite) {
                                this.recallsite.forEach((siteId) => {
                                    formData.append('siteId[]', siteId)
                                })
                            }
                            if (this.ccvalue.length > 0) {
                                for (let k = 0; k < this.ccvalue.length; k++) {
                                    if (!this.defaultEmailList.find(x => x.email == this.ccvalue[k])) {
                                        formData.append('additionalCcEmails', this.ccvalue[k])
                                    }
                                }
                            }
                            for (let i = 0; i < this.defaultEmailList.length; i++) {
                                formData.append('supplierRepCCEmails', this.defaultEmailList[i].email)
                            }
                            recallService.saveCommunicationStep0Info(formData).then((res) => {
                                if (res.error) {
                                    if (res.data.c == 'err_communicationId_exist') {
                                        this.$refs.observer.setErrors({
                                            supplierName: ['Recall Id - Supplier Name combination already exists.'],
                                        })
                                        this.$refs.observer.setErrors({
                                            communicationUId: ['Recall Id - Supplier Name combination already exists.'],
                                        })
                                    }
                                    if (res.data.c == 'err_recall_notification_date') {
                                        this.$refs.observer.setErrors({ notifiedDate: ['Future date is not Allowed.'], })
                                    }
                                } else {
                                    resolve(res.data.d)
                                }
                            })
                        }
                        if (this.orgType == 'SUPPLIER') {
                            supplierRecallService.saveCommunicationStep0Info(formData).then(res => {
                                if (res.error) {
                                    if (res.data.c == 'err_communicationId_exist') {
                                        this.$refs.observer.setErrors({ 'communicationId': [''] })
                                    }
                                } else {
                                    resolve(res.data.d)
                                }
                            })
                        }
                    }
                    else {
                        reject('Invalid step 0')
                    }
                })
            })
        },

        removeDocument(index) {
            this.filesUpload.splice(index, 1)
            this.thumbnailIndex = this.filesUpload.findIndex(x => x.is_thumbnail === true)

        },
        handleFilesUpload() {
            let uploadedFiles = this.$refs.files.files
            let extension = this.$refs.files.files[0]?.name?.split('.')?.pop()
            if (!this.extensionAllowed.includes(extension.toLowerCase()) && !this.extensionImageAllowed.includes(extension.toLowerCase())) {
                return this._showToast('The document types should be either of .pdf, .png, .jpg, .docx, .xlsx, .mp4', { variant: 'danger' })
            }
            for (let i = 0; i < uploadedFiles.length; i++) {
                if (uploadedFiles[i].size == 0) {
                    this._showToast('Documented failed to upload. Please try again.', { variant: 'danger' })
                }
                else {
                    this.filesUpload.push({
                        file: uploadedFiles[i],
                        note: '',
                        is_thumbnail: false,
                        extension: extension
                    })
                }
            }
            this.$refs.files.value = ''
        },
        async handleUrlUpload(url) {
            if (url) {
                try {
                    this.filesUpload.push({
                        file: {title: url, url},
                        note: '',
                        is_thumbnail: false,
                        extension: 'url'
                    })
                } catch (error) {
                    this._showToast('Failed to fetch the URL title', { variant: 'danger' })
                    this.filesUpload.push({
                        file: {title: url, url},
                        note: '',
                        is_thumbnail: false,
                        extension: 'url'
                    })
                }
            } else {
                this._showToast('Invalid URL', { variant: 'danger' })
            }
        },
        dragover(event) {
            event.preventDefault()
            if (!event.currentTarget.classList.contains('bg-dz-hover')) {
                event.currentTarget.classList.add('bg-dz-hover')
            }
        },
        dragleave(event) {
            event.currentTarget.classList.remove('bg-dz-hover')
        },
        drop(event) {
            event.preventDefault()
            const files = event.dataTransfer.files
            let url = event.dataTransfer.getData('text/uri-list')
            if (!url) {
                url = event.dataTransfer.getData('text/plain')
            }
            if (files.length > 0 && files[0].type) {
                this.$refs.files.files = files
                this.handleFilesUpload()
            } else if (url && this.orgType == 'PROVIDER' && this.communicationType === COMMUNICATION_TYPE.RECALL) {
                this.handleUrlUpload(url)
            }
            event.currentTarget.classList.remove('bg-dz-hover')
        },
        handleChangeThumbnail(val) {
            this.thumbnailIndex = val
            this.filesUpload.forEach((x, index) => {
                if (index === val) {
                    if (x.is_thumbnail) {
                        x.is_thumbnail = false
                    } else {
                        x.is_thumbnail = true
                    }
                } else {
                    x.is_thumbnail = false
                }
            })
        },
        disablePastDate() {
            this.info.nextExpectedUpdate = null
            this.info.anticipatedDuration = null
            var disableDate = moment(this.info.communicationDate).format('YYYY/MM/DD')
            this.dependentDisabledDates.to = new Date(this.info.communicationDate)
            if (this.info.communicationDate != null) {
                this.dateHighlight = false
            }
            else if (this.info.communicationDate == null) {
                this.dateHighlight = true
            }
            return disableDate
        },
        disablePastDateNextTaskReminder(){
            if (this.info.nextTaskReminder != null) {
                this.dateHighlightNextTaskReminder = false
            }
            else if (this.info.nextTaskReminder == null) {
                this.dateHighlightNextTaskReminder = true
            }
        },
        disableEditableDate() {
            if (this.$route.params.id != null && this.orgType == 'SUPPLIER' && this.communicationType == this.productDisruption) {
                this.dependentDisabledDates.to = new Date(this.info.communicationDate)
            }
        },
        clearCurrentHiglightDateInEdit() {
            if (this.$route.params.id != null && this.orgType == 'SUPPLIER') {
                this.dateHighlight = false
                this.dateHighlightNextTaskReminder = false
            }
        },
        clearAnicipatedDate() {
            this.eventType = this.info.type != null ? this.info.type : this.eventType
            if (this.info.type == 'DISCONTINUATION') {
                this.info.anticipatedDurationId = null
            }
        },
        setProviderCommunicationName() {
            return this.communicationType == 'PRODUCT DISRUPTION' ? this.info.name :this.info.name = this.info.communicationName
        }

    }
}